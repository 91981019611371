.logout{
  font-family: 'Open Sans', sans-serif;
  list-style: none;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid transparent;
  text-decoration: none;
  color: #34495e !important;
  font-size: .99em;
  padding: 10px 15px;
  text-transform: uppercase;
  text-align: center;
  display: block;

}
.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  body {
    margin: 0;
    background: aliceblue;
}


  .nav-links a {
    border: 1px solid transparent;
    color: #292929;
    text-decoration: none;
    padding: 0.5rem;
  }
  
  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    background: #ffffff;
    border-color: #292929;
    color: #292929;
    border-radius: 25px;
  }
  
  .nav-links button {
    cursor: pointer;
    border: 1px solid #292929;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #292929;
    color: white;
  }
  @media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
    }
  
    .nav-links li {
      margin: 0 0.5rem;
      
      border-color: #292929;
      color: #292929;
      border-radius: 25px;
    }
  
    .nav-links a {
      color: #34495e;
      font-size: .99em;
      padding: 10px 15px;
      text-transform: uppercase;
      text-align: center;
      display: block;
  }
  
    .nav-links button {
      border: 1px solid white;
      color: white;
      background: transparent !important;
    }
    
    .nav-links button:hover,
    .nav-links button:active {
      background: #f8df00;
      color: #292929;
    }
  }
  @media (max-width: 768px) {
    .main-header{
      display: block;
      height: auto;
    }
    img.logo-img {
      display: block;
      width: 7em;
      margin-inline: auto;
      padding-top: 0.5em;
    }
    .authentication {
      margin-inline: auto;
      margin-bottom: 1em !important;
    }
  }