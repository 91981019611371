.main-header {
    width: 80%;
    height: 4rem;
    display: flex;
    align-items: center;
    top: 0;
    background: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    padding: 0 1rem;
    z-index: 5;
    margin-inline: auto;
    margin-top: 1em;
  }
  
  main {
    margin-top: 5rem;
  }
  
  @media (min-width: 768px) {
    .main-header {
      justify-content: space-between;
    }
  }
  img.logo-img {
    display: block;
    width: 7em;
}