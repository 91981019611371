.place-form {
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 30%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input#newlink {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 16px;
  width: 100%;
  min-height: 30px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}
@media (max-width: 768px) {
  .place-form{
    width: 90%;
    margin-inline: auto;
    margin-bottom: 1em;
  }
}
.form-control.undefined {
  color: black;
}