.user-item {
    margin: 1rem;
    width: calc(45% - 2rem);
    min-width: 17.5rem;
  }
  
  a {
    /* width: 100%; */
    height: 100%;
  }
  ul.users-list {
    padding: 0;
    margin: 0;
}


  .user-item {
    align-items: center;
    width: 80%;
    height: 100%;
    text-decoration: none;
    color: white;
    margin-inline: auto;
  }
  .user-item__info {
    display: flex;
}
  
i.fa.fa-gear.fa-spin {
  color: black;
}
  
  .user-item__content {
    padding: 1rem;
  }
  
  .user-item__image {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }
  
  .user-item__info h2 {
    font-size: 2em;
    margin: 0 0 0.5rem 0;
    font-weight: normal;
    color: black;
    text-align: left;
    margin: auto;
    font-family: 'Exo 2', sans-serif;
}
  
  .user-item:hover h2,
  .user-item:active h2,
  .user-item:hover h3,
  .user-item:active h3 {
    color: #292929;
  }
  
  .user-item__info h3 {
    margin: 0;
  }
  .user-item__info > a {
    margin: auto;
    text-align: right;
  }
  i.fa.fa-gear {
    color: black;
}
input#whitelist {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 16px;
  width: 100%;
  min-height: 30px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}
@media (max-width: 768px) {
  .user-item__info > .place-form {
    width: 100%;
  }
  .user-item__info {
    display: block;
  }
  .user-item__info h2 {
    text-align: center;
    margin-bottom: 1em;
  }
  .user-item {
    width: 90%;
  }
  .user-item__info > a {
    margin: auto;
    text-align: center;
    display: block;
}
}
